var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{staticClass:"d-flex row justify-content-between",attrs:{"name":"fade"}},_vm._l((_vm.regiondevicesData.devices),function(deviceitem){return _c('b-col',{key:deviceitem.regionitemkey,attrs:{"cols":""}},[_c('b-card',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html",value:(_vm.getTimeFormat(deviceitem.lastdata.datetime)),expression:"getTimeFormat(deviceitem.lastdata.datetime)",modifiers:{"html":true}}],staticClass:"text-center",attrs:{"no-body":""}},[_c('b-card-body',{staticStyle:{"padding":"1.5rem 1rem","min-width":"140px"}},[_c('b-avatar',{staticClass:"mb-1",attrs:{"variant":_vm.getVariant(deviceitem.devicetype, deviceitem.lastdata),"size":"30"}},[_c('font-awesome-icon',{class:_vm.getSpin(deviceitem.devicetype, deviceitem.lastdata),staticStyle:{"font-size":"20px"},attrs:{"icon":_vm.getIcon(deviceitem.devicetype)}})],1),_c('div',{staticClass:"truncate"},[(
              deviceitem.devicetype === 'inverterfan' ||
              deviceitem.devicetype === 'inverter'
            )?[(
                deviceitem.lastdata.isoffline === false &&
                deviceitem.lastdata.value !== null
              )?[_c('h5',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" "+_vm._s(deviceitem.lastdata.value)+_vm._s(deviceitem.lastdata.unit)+" ")])]:_vm._e(),(
                deviceitem.lastdata.value !== null &&
                deviceitem.lastdata.isoffline === true &&
                _vm.isloading === false &&
                _vm.isvisible === true
              )?[_c('h5',{staticClass:"mb-25 font-weight-bolder text-danger"},[_vm._v(" "+_vm._s(_vm.$t("common.offline"))+" ")])]:_vm._e(),(deviceitem.lastdata.value === null)?_c('h5',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" -- ")]):_vm._e()]:[(
                deviceitem.lastdata.value !== null &&
                deviceitem.lastdata.isoffline !== true
              )?[(deviceitem.lastdata.operationalstate === 1)?_c('h5',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("common.opening"))+" ")]):(deviceitem.lastdata.operationalstate === 0)?_c('h5',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("common.closing"))+" ")]):_vm._e()]:(
                deviceitem.lastdata.value !== null &&
                deviceitem.lastdata.isoffline === true &&
                _vm.isloading !== true &&
                _vm.isvisible === true
              )?[_c('h5',{staticClass:"mb-25 font-weight-bolder text-danger"},[_vm._v(" "+_vm._s(_vm.$t("common.offline"))+" ")])]:_vm._e(),(deviceitem.lastdata.value === null)?_c('h5',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" -- ")]):_vm._e()],_c('span',[_vm._v(_vm._s(deviceitem.name))])],2)],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }