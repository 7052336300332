<template>
  <div>
    <template v-if="tmpregionshowmode === 'simple'">
      <transition name="fade">
        <div class="d-flex align-items-center justify-content-center">
          <!-- show sm down-->
          <div class="row d-flex d-sm-none">
            <!--  -->
            <div
              class="col-12 d-flex align-items-center justify-content-center mb-3"
            >
              <vue-ellipse-progress
                v-bind="options"
                animation="default 1000"
                :data="circlesData"
                :determinate="true"
                style="position: absolute"
              />
              <b-avatar size="160">
                <b-img fluid :src="getRegiontypeimg()" style="height: 125px" />
              </b-avatar>
            </div>
            <!--  -->
            <div class="col-6">
              <b-card no-body class="card-revenue-budget cursor-pointer">
                <b-card-body style="" class="">
                  <b-media no-body class="">
                    <b-media-aside class="">
                      <b-avatar size="56">
                        <font-awesome-icon
                          style="font-size: 1.5rem"
                          icon="fa-solid fa-calendar-days fa-lg"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h2 class="font-weight-bolder mb-0">
                        {{ regiondetailobj.feedingdate }}
                      </h2>
                      <b-card-text
                        style="
                          margin-top: 10px;
                          letter-spacing: 1px;
                          font-size: 1.3rem;
                        "
                      >
                        {{ $t('common.feedingdate') }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-card-body>
              </b-card>
            </div>

            <!--  -->
            <div class="col-6">
              <b-card no-body class="card-revenue-budget cursor-pointer">
                <b-card-body style="" class="">
                  <b-media no-body class="">
                    <b-media-aside class="">
                      <b-avatar size="56">
                        <font-awesome-icon
                          style="font-size: 1.5rem"
                          icon="fa-solid fa-list-ol fa-lg"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h2 class="font-weight-bolder mb-0">
                        {{ regiondetailobj.feedingquantity }}
                      </h2>
                      <b-card-text
                        style="
                          margin-top: 10px;
                          letter-spacing: 1px;
                          font-size: 1.3rem;
                        "
                      >
                        {{ $t('common.feedingquantity') }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-card-body>
              </b-card>
            </div>
          </div>
          <!-- show sm up  -->
          <div class="d-none row d-sm-flex">
            <!--  -->
            <div class="col-4 d-flex align-items-center justify-content-center">
              <b-card
                no-body
                class="card-revenue-budget cursor-pointer"
                style="min-width: 220px"
              >
                <b-card-body style="" class="">
                  <b-media no-body class="">
                    <b-media-aside class="">
                      <b-avatar size="56">
                        <font-awesome-icon
                          style="font-size: 1.5rem"
                          icon="fa-solid fa-calendar-days fa-lg"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h2 class="font-weight-bolder mb-0">
                        {{ regiondetailobj.feedingdate }}
                      </h2>
                      <b-card-text
                        style="
                          margin-top: 10px;
                          letter-spacing: 1px;
                          font-size: 1.3rem;
                        "
                      >
                        {{ $t('common.feedingdate') }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-card-body>
              </b-card>
            </div>
            <!-- png  -->
            <div
              class="col-4 d-flex align-items-center justify-content-center mb-3"
            >
              <vue-ellipse-progress
                v-bind="options"
                animation="default 1000"
                :data="circlesData"
                :determinate="true"
                style="position: absolute"
              />
              <b-avatar size="160">
                <b-img fluid :src="getRegiontypeimg()" style="height: 125px" />
              </b-avatar>
            </div>
            <!--  -->
            <div class="col-4 d-flex align-items-center justify-content-center">
              <b-card
                no-body
                class="card-revenue-budget cursor-pointer"
                style="min-width: 220px; pointer: cursor"
              >
                <b-card-body style="" class="">
                  <b-media no-body class="">
                    <b-media-aside class="">
                      <b-avatar size="56">
                        <font-awesome-icon
                          style="font-size: 1.5rem"
                          icon="fa-solid fa-list-ol fa-lg"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h2 class="font-weight-bolder mb-0">
                        {{ regiondetailobj.feedingquantity }}
                      </h2>
                      <b-card-text
                        style="
                          margin-top: 10px;
                          letter-spacing: 1px;
                          font-size: 1.3rem;
                        "
                      >
                        {{ $t('common.feedingquantity') }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BMedia,
  BCardText,
  VBTooltip,
  BImg,
} from 'bootstrap-vue'

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import Ripple from 'vue-ripple-directive'
import VueEllipseProgress from 'vue-ellipse-progress'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
  Tientech_delay,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'
import { $themeColors } from '@themeConfig'

import img_chicken from '@/assets/images/barn/chicken.png'
import img_swine from '@/assets/images/barn/swine.png'
import img_piglet from '@/assets/images/barn/piglet.png'

import echarttheme from './echarttheme.json'

ECharts.registerTheme('theme-color', echarttheme)
export default {
  components: {
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    // BButton,
    BCardBody,
    // BCardFooter,
    ECharts,
    BCard,
    BCardHeader,
    BRow,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    regiondeviceslist: {
      type: Object,
      default: () => {},
    },
    regionkey: {
      type: String,
      default: () => '',
    },
    regionshowmode: {
      type: String,
      default: () => 'simple',
    },
    regiontype: {
      type: String,
      default: () => 'barn',
    },
  },
  data() {
    return {
      pagename: 'casestatus',
      random_number: 0,
      isvisible: true,
      getdatafrequencyTime: {
        lastdatatime: 30000,
        historydatatime: 5 * 60000,
      },
      overtime: 60000 * 2,
      isloading: false,
      interval_devicecloud_getdevice: 0,
      deviceData: {},
      regioninfo: [],
      timer: {
        isvisible: 0,
      },
      progress: 34,
      circlesData: [
        {
          progress: this.randomNumberInRange(0, 20),
        },
        {
          progress: this.randomNumberInRange(0, 20),
        },
        {
          progress: this.randomNumberInRange(0, 20),
        },
        {
          progress: this.randomNumberInRange(0, 20),
        },
        {
          progress: this.randomNumberInRange(0, 20),
        },
        {
          progress: this.randomNumberInRange(0, 20),
        },
      ],
      colors: [
        'rgb(117,121,255)',
        'rgb(147, 112, 219)',
        'rgb(104,54,243)',
        'rgb(106, 90, 205)',
      ],
      options: {
        determinate: true,
        color: '#7579ff',
        'empty-color': '#324c7e',
        size: 180,
        thickness: 5,
        'line-mode': 'out 5',
        'font-size': '1.5rem',
        'font-color': 'white',
      },
    }
  },
  computed: {
    component() {
      return 'vue-ellipse-progress'
    },
    tmpregionkey: {
      get() {
        return this.regionkey
      },
      set(val) {
        this.$emit('update:regionkey', val)
      },
    },
    tmpregionshowmode: {
      get() {
        return this.regionshowmode
      },
      set(val) {
        this.$emit('update:regionshowmode', val)
      },
    },
    regiondetailobj() {
      try {
        const obj = {
          feedingdate: this.$t('common.unsetting'),
          feedingquantity: this.$t('common.unsetting'),
          feedingage: this.$t('common.unsetting'),
        }
        this.regioninfo.forEach(item => {
          if (item.name === 'feedingdate') {
            if (item.value === '' || item.value === undefined) {
              obj.feedingdate = this.$t('common.unsetting')
            } else {
              const tmpfeeddate = `${item.value}`

              const tmpfeedingdate = new Date(
                tmpfeeddate.split('-')[0],
                tmpfeeddate.split('-')[1] - 1,
                tmpfeeddate.split('-')[2]
              )
              // const tmpfeedingdate = new Date(item.value)
              const nowDate = new Date()
              obj.feedingdate =
                Math.ceil(
                  (nowDate.getTime() - tmpfeedingdate.getTime()) / 86400000
                ) - 1
            }
          }
          if (item.name === 'feedingquantity') {
            if (item.value !== 0 && item.value !== '') {
              obj.feedingquantity = item.value
            } else {
              obj.feedingquantity = this.$t('common.unsetting')
            }
          }
        })

        return obj
      } catch (error) {
        console.log(error)
        return {}
      }
    },
  },
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
      this.getData()
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
      document.removeEventListener('visibilitychange', undefined)
      // clearInterval(this.interval_devicecloud_getdevice)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    page_initialize() {
      try {
        this.casepn = this.$route.params.casepn
        this.randomizeOptions()
        setInterval(this.randomizeOptions, 500)
        document.removeEventListener('visibilitychange', undefined)
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            clearTimeout(this.timer.isvisible)
            this.timer.isvisible = setTimeout(() => {
              this.isvisible = true
            }, 2000)
          } else {
            this.isvisible = false
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply casecloud_getcasesregion_config',
            this.socketevent_casecloud_getcaseregion_config
          )
        } else {
          this.sockets.unsubscribe('reply casecloud_getcasesregion_config')
        }
      } catch (error) {
        console.log(error)
      }
    },
    randomNumberInRange(min = 0, max = 100, rounded = true) {
      const randomNumber = Math.random() * (max - min + 1) + min
      return rounded ? Math.floor(randomNumber) : randomNumber
    },
    randomizeOptions() {
      const gap = this.randomNumberInRange(2, 10)
      const thickness = this.randomNumberInRange(1, 3)

      const updatedData = []

      for (let n = 0; n < 6; n += 1) {
        updatedData.push({
          progress: this.randomNumberInRange(0, 100),
          angle: 20 * n, // randomNumberInRange(0, 100),
          color: this.colors[this.randomNumberInRange(0, 3)],
          gap,
          thickness,
        })
      }
      this.circlesData = updatedData
    },
    getRegiontypeimg() {
      let img = img_chicken
      try {
        switch (this.regiontype) {
          case 'barn_chick':
          case 'barn_chick_egg':
          case 'barn_chicken_whitebroiler':
          case 'barn_chicken_coloredbroiler':
            img = img_chicken
            break
          case 'barn_pig':
          case 'barn_pig_nursery':
            img = img_piglet
            break
          case 'barn_pig_finish':
          case 'barn_pig_childbirth':
          case 'barn_pig_Breeding':
            img = img_swine
            break
          default:
            img = img_chicken
            break
        }
        return img
      } catch (error) {
        console.log(error)
        return img
      }
    },
    getData() {
      try {
        const vuethis = this
        const queryParams = {
          casepn: vuethis.casepn,
          regionpn: vuethis.regionkey,
          callback_params: `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionkey}_${vuethis.random_number}`,
        }
        this.$socket.emit('casecloud_getcasesregion_config', queryParams)
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_casecloud_getcaseregion_config(params) {
      try {
        const vuethis = this
        let obj = [
          { name: 'feedingdate', value: '' },
          { name: 'feedingquantity', value: '' },
          { name: 'house_length', value: '' },
          { name: 'house_height', value: '' },
          { name: 'house_width', value: '' },
          { name: 'house_sectionalarea', value: '' },
        ]
        // operatingtime feedingdate飼養日期
        if (
          params.callback_params !==
          `${vuethis.pagename}_${vuethis.casepn}_${vuethis.regionkey}_${vuethis.random_number}`
        ) {
          return
        }
        if (params === undefined || params.type === 'error') {
          return
        }
        if (params.msg === undefined) {
          this.getData()
          return
        }
        if (params.msg.data !== undefined) {
          if (Object.keys(params.msg.data).length > 0) {
            if (params.msg.data.parameter_data.length !== 0) {
              obj = params.msg.data.parameter_data
            }
          }
        }
        this.regioninfo = []
        this.regioninfo = obj
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
<style lang="scss">
// .echarts {
//   width: 100%;
//   height: 180px;
// }
</style>
