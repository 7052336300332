<template>
  <transition name="fade">
    <div>
      <template v-if="tmploadedData === false">
        <b-row
          class="match-height d-flex flex-column align-items-center justify-content-center"
          style="height: calc(50vh)"
        >
          <b-col
            class="d-flex flex-column align-items-center justify-content-center p-3"
          >
            <h3>{{ $t(`common.loading`) }}</h3>
            <div class="demo-inline-spacing">
              <b-spinner class="mr-1" variant="info" />
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-if="tmploadedData === true && regionnumber === 0">
        <b-row class="match-height">
          <b-col
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <h3>{{ $t('common.nodata') }}</h3>
            <b-img fluid :src="imgUrl" />
          </b-col>
        </b-row>
      </template>
      <template v-if="tmploadedData === true && regionnumber > 0">
        <b-row class="d-flex align-items-start justify-content-end">
          <b-col v-if="regionlist.length > 1" cols="auto">
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="showregionname"
              variant="outline-primary"
              class="dropdown-icon-wrapper"
              right
            >
              <template
                #button-content
                class="d-flex align-items-center justify-content-center"
              >
                <font-awesome-icon
                  style="font-size: 0.8rem; margin-right: 0.5rem"
                  icon="fa-solid fa-warehouse"
                />
                <span class="mr-1">{{ showregionname }}</span>
              </template>
              <b-dropdown-item @click="changeShowRegion('all')">{{
                $t('common.all')
              }}</b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                v-for="regionitem in regionlist"
                :key="regionitem.regionkey"
                @click="changeShowRegion(regionitem.regionkey)"
              >
                {{ regionitem.name }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col cols="auto">
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="$t('common.mode')"
              variant="outline-primary"
              class="dropdown-icon-wrapper"
              right
            >
              <template
                #button-content
                class="d-flex align-items-center justify-content-center"
              >
                <font-awesome-icon
                  v-if="showmode === 'simple'"
                  style="font-size: 1rem; margin-right: 0.5rem"
                  icon="fa-solid fa-child"
                />
                <font-awesome-icon
                  v-if="showmode === 'advanced'"
                  style="font-size: 1rem; margin-right: 0.5rem"
                  icon="fa-solid fa-user-doctor"
                />
                <span class="mr-1">{{ $t(`common.${showmode}`) }}</span>
              </template>
              <b-dropdown-item @click="changeShowMode('simple')">
                <font-awesome-icon
                  style="font-size: 0.8rem; margin-right: 1rem"
                  icon="fa-solid fa-child"
                />{{ $t('common.simple') + $t('common.mode') }}</b-dropdown-item>
              <b-dropdown-item @click="changeShowMode('advanced')">
                <font-awesome-icon
                  style="font-size: 0.8rem; margin-right: 1rem"
                  icon="fa-solid fa-user-doctor"
                />{{
                  $t('common.advanced') + $t('common.mode')
                }}</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <transition-group name="fade">
          <b-row
            v-for="regionitem in regionlist"
            :key="regionitem.regionkey"
            :class="getRegionisShow(regionitem.showregion)"
          >
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center justify-content-start my-1">
                <b-avatar size="38" rounded style="background-color: #0000">
                  <font-awesome-icon
                    style="font-size: 20px; margin-bottom: 0.5rem"
                    icon="fa-solid fa-warehouse"
                  />
                </b-avatar>
                <h2 style="margin-left: 0.5rem; letter-spacing: 3px">
                  {{ regionitem.name }}
                </h2>
              </div>
              <div>
                <!-- device control setting page -->
                <b-dropdown no-caret toggle-class="p-1" right variant="primary">
                  <template #button-content>
                    <font-awesome-icon
                      style="font-size: 15px"
                      icon="fa-solid fa-table-list"
                    />
                  </template>
                  <b-dropdown-item
                    :to="{
                      name: 'devicecontrolsetting',
                      params: { regionkey: regionitem.regionkey },
                    }"
                  >
                    <div
                      class="d-flex align-items-center justify-content-start"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-laptop-code"
                        style="font-size: 20px"
                      />
                      <span class="ml-1" style="letter-spacing: 3px">{{
                        $t('pages.devicecontrolsetting.pagetitle')
                      }}</span>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
            <b-col cols="12 px-0">
              <template
                v-if="tmpdeviceregionData[regionitem.regionkey] !== undefined"
                >
                <CaseStatusInfo
                  :regionkey="regionitem.regionkey"
                  :regiondeviceslist="tmpdeviceregionData[regionitem.regionkey]"
                  :regionshowmode="showmode"
                  :regiontype="regionitem.type"
                />
                <CaseStatusDevicesData_Fan
                  :regionkey="regionitem.regionkey"
                  :regiondeviceslist="tmpdeviceregionData[regionitem.regionkey]"
                  :regionshowmode="showmode"
                />
                <CaseStatusDevicesData_Other
                  :regionkey="regionitem.regionkey"
                  :regiondeviceslist="tmpdeviceregionData[regionitem.regionkey]"
                  :regionshowmode="showmode"
                />
                <CaseStatusDevicesData_Sensor
                  :regionkey="regionitem.regionkey"
                  :regiondeviceslist="tmpdeviceregionData[regionitem.regionkey]"
                  :regionshowmode="showmode"
                />
              </template>
              <hr style="width: 100%">
            </b-col>
          </b-row>
        </transition-group>
      </template>
    </div>
  </transition>
</template>
<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BAvatar,
  BSpinner,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import store from '@/store/index'
import CaseStatusDevicesData_Sensor from './CaseStatusDevicesData_Sensor.vue'
import CaseStatusDevicesData_Fan from './CaseStatusDevicesData_Fan.vue'
import CaseStatusDevicesData_Other from './CaseStatusDevicesData_Other.vue'
import CaseStatusInfo from './CaseStatusInfo.vue'

export default {
  components: {
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BAvatar,
    // BButton,
    BSpinner,
    BRow,
    BCol,
    BImg,
    CaseStatusDevicesData_Fan,
    CaseStatusDevicesData_Sensor,
    CaseStatusDevicesData_Other,
    CaseStatusInfo,
  },
  directives: {
    Ripple,
  },
  props: {
    loadedData: {
      type: Boolean,
      default: () => false,
    },
    deviceregionData: {
      type: Object,
      default: () => {},
    },
    showregionData: {
      type: String,
      default: () => 'ALL',
    },
    regiondeviceslist: {
      type: Object,
      default: () => {},
    },
    caseregion: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data: {},
      showregion: 'all',
      showregionname: '',
      showmode: 'simple',
      downImg: require('@/assets/images/illustration/notfounddata.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/illustration/notfounddata-w.png')
        return this.downImg
      }
      return this.downImg
    },
    tmploadedData: {
      get() {
        return this.loadedData
      },
      set(val) {
        this.$emit('update:loadedData', val)
      },
    },
    tmpdeviceregionData: {
      get() {
        return this.deviceregionData
      },
      set(val) {
        this.$emit('update:deviceregionData', val)
      },
    },
    tmpregiondeviceslist: {
      get() {
        return this.regiondeviceslist
      },
      set(val) {
        console.log(val)
        this.$emit('update:regiondeviceslist', val)
      },
    },
    tmpcaseregion: {
      get() {
        return this.caseregion
      },
      set(val) {
        console.log(val)
        this.$emit('update:caseregion', val)
      },
    },
    regionnumber() {
      try {
        if (this.regiondeviceslist === undefined) {
          return 0
        }
        return Object.keys(this.regiondeviceslist).length
      } catch (error) {
        console.log(error)
        return 0
      }
    },
    regionlist() {
      try {
        if (this.caseregion === undefined) {
          return []
        }
        const tmpArray = []
        Object.keys(this.caseregion).forEach(regionkey => {
          const tmpobj = this.caseregion[regionkey]
          tmpobj.regionkey = regionkey
          if (this.showregion === 'all') {
            tmpobj.showregion = true
          } else if (this.showregion === regionkey) {
            tmpobj.showregion = true
          } else {
            tmpobj.showregion = false
          }
          tmpArray.push(tmpobj)
        })
        tmpArray.sort((a, b) => {
          try {
            if (a.index !== undefined) {
              if (a.index > b.index) {
                return 1
              }
            }
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          } catch (error) {
            console.log(error)
            return 0
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return 0
      }
    },
    regionAlllist() {
      try {
        if (this.caseregion === undefined) {
          return []
        }
        const tmpArray = []
        Object.keys(this.caseregion).forEach(regionkey => {
          const tmpobj = this.caseregion[regionkey]
          tmpobj.regionkey = regionkey
          tmpArray.push(tmpobj)
        })
        tmpArray.sort((a, b) => {
          try {
            if (a.index !== undefined) {
              if (a.index > b.index) {
                return 1
              }
            }
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          } catch (error) {
            console.log(error)
            return 0
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return 0
      }
    },
  },
  mounted() {
    try {
      this.showregionname = this.$t('common.all')
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      //
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    getRegionisShow(isshow) {
      if (this.showregion === 'all') {
        return ''
      }
      if (isshow !== true) {
        return 'd-none'
      }
      return ''
    },
    changeShowRegion(regionkey) {
      this.showregion = regionkey
      if (regionkey === 'all') {
        this.showregionname = this.$t('common.all')
      } else {
        this.showregionname = this.tmpcaseregion[regionkey].name
      }
    },
    changeShowMode(mode) {
      if (mode !== undefined) {
        this.showmode = mode
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.dark-layout .dropdown-menu .dropdown-item {
  font-weight: 600;
}
</style>
