/* eslint-disable import/prefer-default-export */
const pig = [
  {
    agedays: 21,
    targetvalue: 32,
    heatup: 32,
    cooling: 35,
    humidity: 60,
    aircoolingfactor: 8,
    minventilationtime: 10,
    minventilationspeed: 0.1,
    maxventilationspeed: 0.5,
    weight: 5.75,
    growth: 0.23,
  },
  {
    agedays: 28,
    targetvalue: 30,
    heatup: 31,
    cooling: 34,
    humidity: 60,
    aircoolingfactor: 8,
    minventilationtime: 10,
    minventilationspeed: 0.1,
    maxventilationspeed: 0.8,
    weight: 7,
    growth: 0.2,
  },
  {
    agedays: 35,
    targetvalue: 29,
    heatup: 29,
    cooling: 34,
    humidity: 60,
    aircoolingfactor: 7,
    minventilationtime: 10,
    minventilationspeed: 0.1,
    maxventilationspeed: 0.8,
    weight: 9.5,
    growth: 0.32,
  },
  {
    agedays: 42,
    targetvalue: 28,
    heatup: 28,
    cooling: 33,
    humidity: 60,
    aircoolingfactor: 6,
    minventilationtime: 10,
    minventilationspeed: 0.1,
    maxventilationspeed: 0.8,
    weight: 12,
    growth: 0.379,
  },
  {
    agedays: 49,
    targetvalue: 27,
    heatup: 27,
    cooling: 32,
    humidity: 60,
    aircoolingfactor: 5,
    minventilationtime: 10,
    minventilationspeed: 0.1,
    maxventilationspeed: 1,
    weight: 16,
    growth: 0.42,
  },
  {
    agedays: 56,
    targetvalue: 26,
    heatup: 26,
    cooling: 31.5,
    humidity: 60,
    aircoolingfactor: 4,
    minventilationtime: 10,
    minventilationspeed: 0.2,
    maxventilationspeed: 1,
    weight: 21,
    growth: 0.58,
  },
  {
    agedays: 63,
    targetvalue: 25,
    heatup: 25,
    cooling: 31,
    humidity: 60,
    aircoolingfactor: 3.5,
    minventilationtime: 10,
    minventilationspeed: 0.2,
    maxventilationspeed: 1.1,
    weight: 25,
    growth: 0.62,
  },
  {
    agedays: 70,
    targetvalue: 24,
    heatup: 24,
    cooling: 30,
    humidity: 60,
    aircoolingfactor: 3.5,
    minventilationtime: 10,
    minventilationspeed: 0.3,
    maxventilationspeed: 1.2,
    weight: 31,
    growth: 0.694,
  },
  {
    agedays: 77,
    targetvalue: 23,
    heatup: 23,
    cooling: 29.5,
    humidity: 60,
    aircoolingfactor: 3.5,
    minventilationtime: 10,
    minventilationspeed: 0.3,
    maxventilationspeed: 1.2,
    weight: 35,
    growth: 0.720,
  },
  {
    agedays: 84,
    targetvalue: 22,
    heatup: 22,
    cooling: 29,
    humidity: 60,
    aircoolingfactor: 3,
    minventilationtime: 10,
    minventilationspeed: 0.3,
    maxventilationspeed: 1.4,
    weight: 41,
    growth: 0.857,
  },
  {
    agedays: 91,
    targetvalue: 21,
    heatup: 21,
    cooling: 28.5,
    humidity: 60,
    aircoolingfactor: 3.5,
    minventilationtime: 10,
    minventilationspeed: 0.3,
    maxventilationspeed: 1.4,
    weight: 48,
    growth: 0.880,
  },
  {
    agedays: 98,
    targetvalue: 20,
    heatup: 20,
    cooling: 28,
    humidity: 60,
    aircoolingfactor: 3.5,
    minventilationtime: 10,
    minventilationspeed: 0.3,
    maxventilationspeed: 1.5,
    weight: 54,
    growth: 0.933,
  },
  {
    agedays: 105,
    targetvalue: 19,
    heatup: 19,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 3.5,
    minventilationtime: 10,
    minventilationspeed: 0.4,
    maxventilationspeed: 1.5,
    weight: 61,
    growth: 1,
  },
  {
    agedays: 112,
    targetvalue: 18,
    heatup: 18,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 3,
    minventilationtime: 10,
    minventilationspeed: 0.4,
    maxventilationspeed: 1.6,
    weight: 66,
    growth: 1.1,
  },
  {
    agedays: 119,
    targetvalue: 18,
    heatup: 17,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 3,
    minventilationtime: 10,
    minventilationspeed: 0.4,
    maxventilationspeed: 1.6,
    weight: 73,
    growth: 1.15,
  },
  {
    agedays: 126,
    targetvalue: 18,
    heatup: 16,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2.8,
    minventilationtime: 10,
    minventilationspeed: 0.5,
    maxventilationspeed: 1.8,
    weight: 79,
    growth: 1.18,
  },
  {
    agedays: 133,
    targetvalue: 18,
    heatup: 15,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2.8,
    minventilationtime: 10,
    minventilationspeed: 0.5,
    maxventilationspeed: 1.8,
    weight: 85,
    growth: 1.12,
  },
  {
    agedays: 140,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2.8,
    minventilationtime: 10,
    minventilationspeed: 0.5,
    maxventilationspeed: 1.9,
    weight: 91,
    growth: 1.13,
  },
  {
    agedays: 147,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2.8,
    minventilationtime: 10,
    minventilationspeed: 0.6,
    maxventilationspeed: 1.9,
    weight: 98,
    growth: 1,
  },
  {
    agedays: 154,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2.5,
    minventilationtime: 10,
    minventilationspeed: 0.6,
    maxventilationspeed: 1.9,
    weight: 106,
    growth: 1.1,
  },
  {
    agedays: 161,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2.5,
    minventilationtime: 10,
    minventilationspeed: 0.6,
    maxventilationspeed: 1.9,
    weight: 114,
    growth: 1.1,
  },
  {
    agedays: 168,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2.5,
    minventilationtime: 10,
    minventilationspeed: 0.6,
    maxventilationspeed: 1.9,
    weight: 122,
    growth: 1.1,
  },
  {
    agedays: 175,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2,
    minventilationtime: 10,
    minventilationspeed: 0.7,
    maxventilationspeed: 2,
    weight: 129,
    growth: 1.1,
  },
  {
    agedays: 182,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2,
    minventilationtime: 10,
    minventilationspeed: 0.7,
    maxventilationspeed: 2,
    weight: 138,
    growth: 1.1,
  },
  {
    agedays: 189,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2,
    minventilationtime: 10,
    minventilationspeed: 0.8,
    maxventilationspeed: 2,
    weight: 147,
    growth: 1.1,
  },
  {
    agedays: 196,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2,
    minventilationtime: 10,
    minventilationspeed: 0.8,
    maxventilationspeed: 2.2,
    weight: 154,
    growth: 1.1,
  },
  {
    agedays: 203,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2,
    minventilationtime: 10,
    minventilationspeed: 0.8,
    maxventilationspeed: 2.2,
    weight: 161,
    growth: 1.1,
  },
  {
    agedays: 210,
    targetvalue: 18,
    heatup: 14,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2,
    minventilationtime: 10,
    minventilationspeed: 0.8,
    maxventilationspeed: 2.2,
    weight: 168,
    growth: 1.1,
  },
]
const broiler = [
  {
    agedays: 0,
    targetvalue: 35,
    heatup: 36,
    cooling: 38,
    humidity: 60,
    aircoolingfactor: 8,
    minventilationtime: 10,
    minventilationspeed: 0.1,
    maxventilationspeed: 0.5,
  },
  {
    agedays: 4,
    targetvalue: 32,
    heatup: 30,
    cooling: 38,
    humidity: 60,
    aircoolingfactor: 7.6,
    minventilationtime: 12,
    minventilationspeed: 0.1,
    maxventilationspeed: 0.8,
  },
  {
    agedays: 7,
    targetvalue: 29.5,
    heatup: 29,
    cooling: 32.5,
    humidity: 60,
    aircoolingfactor: 7,
    minventilationtime: 30,
    minventilationspeed: 0.1,
    maxventilationspeed: 1,
  },
  {
    agedays: 14,
    targetvalue: 27.5,
    heatup: 27,
    cooling: 31,
    humidity: 60,
    aircoolingfactor: 6,
    minventilationtime: 55,
    minventilationspeed: 0.1,
    maxventilationspeed: 1.8,
  },
  {
    agedays: 21,
    targetvalue: 25.5,
    heatup: 25,
    cooling: 29,
    humidity: 60,
    aircoolingfactor: 5,
    minventilationtime: 85,
    minventilationspeed: 0.1,
    maxventilationspeed: 2.5,
  },
  {
    agedays: 28,
    targetvalue: 23.5,
    heatup: 23,
    cooling: 28,
    humidity: 60,
    aircoolingfactor: 4.2,
    minventilationtime: 110,
    minventilationspeed: 0.1,
    maxventilationspeed: 2.8,
  },
  {
    agedays: 35,
    targetvalue: 21.5,
    heatup: 21,
    cooling: 28,
    humidity: 60,
    aircoolingfactor: 3.5,
    minventilationtime: 140,
    minventilationspeed: 0.1,
    maxventilationspeed: 3,
  },
  {
    agedays: 42,
    targetvalue: 21,
    heatup: 20,
    cooling: 28,
    humidity: 60,
    aircoolingfactor: 3,
    minventilationtime: 180,
    minventilationspeed: 0.1,
    maxventilationspeed: 3,
  },
  {
    agedays: 49,
    targetvalue: 21,
    heatup: 20,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2.5,
    minventilationtime: 210,
    minventilationspeed: 0.1,
    maxventilationspeed: 3,
  },
  {
    agedays: 56,
    targetvalue: 21,
    heatup: 20,
    cooling: 27.5,
    humidity: 60,
    aircoolingfactor: 2,
    minventilationtime: 240,
    minventilationspeed: 0.1,
    maxventilationspeed: 3,
  },
]
const empty = [
  {
    agedays: 0,
    targetvalue: 30,
    heatup: 36,
    cooling: 38,
    humidity: 60,
    aircoolingfactor: 8,
    minventilationtime: 10,
    minventilationspeed: 0.1,
    maxventilationspeed: 0.5,
  },
]
export const Tientech_getDefauleGrowthcurve = animal => {
  try {
    switch (animal) {
      case 'broiler':
        return broiler
      case 'pig':
        return pig
      case 'empty':
        return empty
      default:
        return empty
    }
  } catch (error) {
    console.log(error)
    return {}
  }
}
