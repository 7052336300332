<template>
  <transition-group
    name="fade"
    class="d-flex row justify-content-between"
    style=""
  >
    <b-col
      v-for="deviceitem in regiondevicesData.devices"
      :key="deviceitem.regionitemkey"
      cols=""
    >
      <b-card
        v-b-tooltip.html="getTimeFormat(deviceitem.lastdata.datetime)"
        class="text-center"
        no-body
      >
        <b-card-body style="padding: 1.5rem 1rem; min-width: 140px">
          <b-avatar
            class="mb-1"
            :variant="getVariant(deviceitem.devicetype, deviceitem.lastdata)"
            size="30"
          >
            <font-awesome-icon
              style="font-size: 20px"
              :icon="getIcon(deviceitem.devicetype)"
              :class="getSpin(deviceitem.devicetype, deviceitem.lastdata)"
            />
          </b-avatar>
          <div class="truncate">
            <!-- inverterfan inverter -->
            <template
              v-if="
                deviceitem.devicetype === 'inverterfan' ||
                deviceitem.devicetype === 'inverter'
              "
            >
              <template
                v-if="
                  deviceitem.lastdata.isoffline === false &&
                  deviceitem.lastdata.value !== null
                "
              >
                <h5 class="mb-25 font-weight-bolder">
                  {{ deviceitem.lastdata.value }}{{ deviceitem.lastdata.unit }}
                </h5>
              </template>

              <template
                v-if="
                  deviceitem.lastdata.value !== null &&
                  deviceitem.lastdata.isoffline === true &&
                  isloading === false &&
                  isvisible === true
                "
              >
                <h5 class="mb-25 font-weight-bolder text-danger">
                  {{ $t(`common.offline`) }}
                </h5>
              </template>
              <h5
                v-if="deviceitem.lastdata.value === null"
                class="mb-25 font-weight-bolder"
              >
                --
              </h5>
            </template>

            <template v-else>
              <!-- fan wetpad -->
              <template
                v-if="
                  deviceitem.lastdata.value !== null &&
                  deviceitem.lastdata.isoffline !== true
                "
              >
                <h5
                  v-if="deviceitem.lastdata.operationalstate === 1"
                  class="mb-25 font-weight-bolder"
                >
                  {{ $t(`common.opening`) }}
                </h5>
                <h5
                  v-else-if="deviceitem.lastdata.operationalstate === 0"
                  class="mb-25 font-weight-bolder"
                >
                  {{ $t(`common.closing`) }}
                </h5>
              </template>

              <template
                v-else-if="
                  deviceitem.lastdata.value !== null &&
                  deviceitem.lastdata.isoffline === true &&
                  isloading !== true &&
                  isvisible === true
                "
              >
                <h5 class="mb-25 font-weight-bolder text-danger">
                  {{ $t(`common.offline`) }}
                </h5>
              </template>
              <h5
                v-if="deviceitem.lastdata.value === null"
                class="mb-25 font-weight-bolder"
              >
                --
              </h5>
            </template>
            <span>{{ deviceitem.name }}</span>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </transition-group>
</template>

<script>
import { BRow, BCol, BCard, BAvatar, VBTooltip, BCardBody } from 'bootstrap-vue'
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import Ripple from 'vue-ripple-directive'
import {
  Tientech_getCookie,
  Tientech_getPageRandomNumber,
  Tientech_delay,
} from '@/libs/tientech/api'
import {
  Tientech_CaseInit,
  Tientech_CaseDeviceInit,
} from '@/libs/tientech/case'

export default {
  components: {
    BCardBody,
    BCard,
    BAvatar,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    devicetype: {
      type: String,
      default: () => '',
    },
    regiondeviceslist: {
      type: Object,
      default: () => {},
    },
    regionkey: {
      type: String,
      default: () => '',
    },
    regionshowmode: {
      type: String,
      default: () => 'simple',
    },
  },
  data() {
    return {
      pagename: 'casestatus',
      deviceHistoryData: {},
      autogethistory: {},
      random_number: 0,
      residenceTime: {
        lastdatatime: 0,
        historydatatime: 0,
      },
      getdatafrequencyTime: {
        lastdatatime: 30000,
        historydatatime: 5 * 60000,
      },
      timer: {
        isvisible: 0,
      },
      isvisible: true,
      overtime: 60000 * 2,
      isloading: false,
      interval_devicecloud_getdevice: 0,
      deviceData: {},
      regiondevicesHistoryData: {
        max: null,
        min: null,
      },
      chartoption: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false,
          },
        },
        grid: {
          show: false,
          top: 10,
          left: '40px',
          right: '15px',
          bottom: '30px',
          containLabel: false,
        },
        xAxis: {
          show: true,
          splitLine: { show: false },
          type: 'time',
          interval: 1000 * 3600,
        },
        yAxis: {
          show: true,
          boundaryGap: [-5, '99%'],
          splitLine: { show: false },
          type: 'value',
          min: value => value.min * 0.95,
          max: value => value.max * 1.05,
        },
        series: [],
      },
    }
  },
  computed: {
    tmpdevicetype: {
      get() {
        return this.devicetype
      },
      set(val) {
        this.$emit('update:devicetype', val)
      },
    },
    tmpregionkey: {
      get() {
        return this.regionkey
      },
      set(val) {
        this.$emit('update:regionkey', val)
      },
    },
    tmpregiondeviceslist: {
      get() {
        return this.regiondeviceslist
      },
      set(val) {
        this.$emit('update:regiondeviceslist', val)
      },
    },
    tmpregionshowmode: {
      get() {
        return this.regionshowmode
      },
      set(val) {
        this.$emit('update:regionshowmode', val)
      },
    },
    regiondevicesData() {
      try {
        const vuethis = this
        const tmpDeviceObj = {}
        const devicetypeArray = this.getRegionDeviceTypeArray_Fan(
          this.tmpregiondeviceslist
        )
        const tmdevices = []

        devicetypeArray.forEach(tmpdevicetype => {
          this.regiondevices(tmpdevicetype).forEach(deviceitem => {
            const tmpObj = { ...deviceitem }
            const smcpn = deviceitem.smcpn
            const devicepn = deviceitem.devicepn
            tmpObj.lastdata = {
              value: null,
              unit: '',
              datetime: 0,
              isoffline: true,
            }
            tmpObj.devicetype = tmpdevicetype
            if (vuethis.deviceData !== undefined) {
              if (vuethis.deviceData[smcpn] !== undefined) {
                if (vuethis.deviceData[smcpn].devices !== undefined) {
                  if (
                    vuethis.deviceData[smcpn].devices[devicepn] !== undefined
                  ) {
                    if (
                      vuethis.deviceData[smcpn].devices[devicepn]
                        .latest_rawdata !== undefined &&
                      vuethis.deviceData[smcpn].devices[devicepn].latest_rawdata
                        .data !== undefined
                    ) {
                      if (
                        tmpdevicetype === 'inverter' ||
                        tmpdevicetype === 'inverterfan'
                      ) {
                        tmpObj.lastdata = {
                          value: Number(
                            Number(
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.data['Output frequency#Hz']
                            ).toFixed(0)
                          ),
                          unit: 'Hz',
                          datetime:
                            vuethis.deviceData[smcpn].devices[devicepn]
                              .latest_rawdata.datetime,
                          operationalstate:
                            vuethis.deviceData[smcpn].devices[devicepn]
                              .latest_rawdata.data['Operational state'],
                        }
                      } else {
                        let operationalstate = 0
                        if (
                          vuethis.deviceData[smcpn].devices[devicepn]
                            .latest_rawdata.do_mode !== undefined
                        ) {
                          if (
                            vuethis.deviceData[smcpn].devices[devicepn]
                              .latest_rawdata.do_mode === 'nc'
                          ) {
                            if (
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.data[
                                vuethis.deviceData[smcpn].devices[devicepn]
                                  .latest_rawdata.replace
                              ] === 1
                            ) {
                              operationalstate = 0
                            } else {
                              operationalstate = 1
                            }
                          }
                        } else {
                          operationalstate =
                            vuethis.deviceData[smcpn].devices[devicepn]
                              .latest_rawdata.data[
                              vuethis.deviceData[smcpn].devices[devicepn]
                                .latest_rawdata.replace
                            ]
                        }
                        tmpObj.lastdata = {
                          value: Number(operationalstate).toFixed(0),
                          unit: '',
                          datetime:
                            vuethis.deviceData[smcpn].devices[devicepn]
                              .latest_rawdata.datetime,
                          operationalstate,
                        }
                      }
                      if (
                        new Date().getTime() - tmpObj.lastdata.datetime <
                        vuethis.overtime
                      ) {
                        tmpObj.lastdata.isoffline = false
                      } else {
                        tmpObj.lastdata.isoffline = true
                      }
                    }
                  }
                }
              }
            }
            tmdevices.push(tmpObj)
          })
          tmpDeviceObj.devices = tmdevices
        })
        vuethis.$forceUpdate()
        return tmpDeviceObj
      } catch (error) {
        console.log(error)
        return {}
      }
    },
  },
  created() {
    try {
      this.random_number = Tientech_getPageRandomNumber()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    try {
      this.socketioevent_initialize()
      this.page_initialize()
      this.getData()
      clearInterval(this.interval_devicecloud_getdevice)
      this.interval_devicecloud_getdevice = setInterval(() => {
        this.getData()
      }, 1000)
    } catch (error) {
      console.log(error)
    }
  },
  beforeDestroy() {
    try {
      this.socketioevent_initialize(true)
      clearInterval(this.interval_devicecloud_getdevice)
      document.removeEventListener('visibilitychange', undefined)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    page_initialize() {
      try {
        this.casepn = this.$route.params.casepn
        document.removeEventListener('visibilitychange', undefined)
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            clearTimeout(this.timer.isvisible)
            this.timer.isvisible = setTimeout(() => {
              this.isvisible = true
            }, 2000)
          } else {
            this.isvisible = false
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    socketioevent_initialize(params) {
      try {
        if (params === undefined || params === false) {
          this.sockets.subscribe(
            'reply_devicecloud_getdevicehistorydata',
            this.socketevent_reply_devicecloud_getdevicehistorydata
          )
          this.sockets.subscribe(
            'reply_devicecloud_getdevicelatestrawdata',
            this.socketevent_reply_devicecloud_getdevicelatestrawdata
          )
        } else {
          this.sockets.unsubscribe('reply_devicecloud_getdevicehistorydata')
          this.sockets.unsubscribe('reply_devicecloud_getdevicelatestrawdata')
        }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_reply_devicecloud_getdevicehistorydata(params) {
      try {
        if (
          this.$router !== undefined &&
          this.$router.history !== undefined &&
          this.$router.history.current
        ) {
          if (this.$router.history.current.name !== undefined) {
            if (this.$router.history.current.name !== 'casestatus') {
              return
            }
          }
        }

        if (params === undefined || params.type === 'error') {
          return
        }
        if (params.msg === undefined) {
          return
        }
        const tmpdeviceHistoryData = { ...this.deviceHistoryData }
        Object.keys(params.msg).forEach(smcpn => {
          Object.keys(params.msg[smcpn]).forEach(devicepn => {
            if (tmpdeviceHistoryData[smcpn] === undefined) {
              tmpdeviceHistoryData[smcpn] = {}
            }
            if (tmpdeviceHistoryData[smcpn][devicepn] === undefined) {
              tmpdeviceHistoryData[smcpn][devicepn] = {}
            }
            if (tmpdeviceHistoryData[smcpn][devicepn].history === undefined) {
              tmpdeviceHistoryData[smcpn][devicepn].history = {}
            }
            if (
              tmpdeviceHistoryData[smcpn][devicepn].history.detaildata ===
              undefined
            ) {
              tmpdeviceHistoryData[smcpn][devicepn].history.detaildata = {}
            }
            tmpdeviceHistoryData[smcpn][devicepn].history.detaildata =
              Object.assign(
                [],
                tmpdeviceHistoryData[smcpn][devicepn].history.detaildata,
                params.msg[smcpn][devicepn]
              )
            Object.keys(
              tmpdeviceHistoryData[smcpn][devicepn].history.detaildata
            ).forEach(dateindex => {
              const tmpdevicedata =
                tmpdeviceHistoryData[smcpn][devicepn].history.detaildata[
                  dateindex
                ]
              let targetvalue = null
              let targetunit = ''
              const targetdatetime = tmpdevicedata.datetime
              const targetname = tmpdevicedata.name
              if (
                tmpdevicedata.data !== undefined &&
                tmpdevicedata.replace !== undefined &&
                tmpdevicedata.replace !== ''
              ) {
                targetvalue = tmpdevicedata.data[tmpdevicedata.replace]
                targetunit =
                  tmpdevicedata.replace.split('#').length > 1
                    ? tmpdevicedata.replace.split('#')[1]
                    : ''
              }
              if (
                tmpdeviceHistoryData[smcpn][devicepn].history.summarydata ===
                undefined
              ) {
                tmpdeviceHistoryData[smcpn][devicepn].history.summarydata = {}
              }
              if (
                tmpdeviceHistoryData[smcpn][devicepn].history.summarydata[
                  targetdatetime
                ] === undefined
              ) {
                tmpdeviceHistoryData[smcpn][devicepn].history.summarydata[
                  targetdatetime
                ] = {}
              }
              tmpdeviceHistoryData[smcpn][devicepn].history.summarydata[
                targetdatetime
              ] = {
                datetime: targetdatetime,
                value: targetvalue,
                unit: targetunit,
                name: targetname,
              }
            })
          })
          if (this.autogethistory === undefined) {
            this.autogethistory = {}
          }
          if (this.autogethistory[smcpn] === undefined) {
            this.autogethistory[smcpn] = {}
          }
          this.autogethistory[smcpn] = new Date().getTime()
          this.autogethistory = { ...this.autogethistory }
        })
        this.deviceHistoryData = { ...tmpdeviceHistoryData }
      } catch (error) {
        console.log(error)
      }
    },
    socketevent_reply_devicecloud_getdevicelatestrawdata(params) {
      try {
        const vuethis = this
        if (
          this.$router !== undefined &&
          this.$router.history !== undefined &&
          this.$router.history.current
        ) {
          if (this.$router.history.current.name !== undefined) {
            if (this.$router.history.current.name !== 'casestatus') {
              return
            }
          }
        }
        if (params === undefined || params.type === 'error') {
          this.isloading = false
          return
        }
        if (params.msg === undefined) {
          this.isloading = false
          return
        }
        const tmpdeviceData = { ...vuethis.deviceData }
        Object.keys(params.msg).forEach(smcpn => {
          Object.keys(params.msg[smcpn]).forEach(devicepn => {
            try {
              if (tmpdeviceData[smcpn] === undefined) {
                tmpdeviceData[smcpn] = {}
              }
              if (tmpdeviceData[smcpn].devices === undefined) {
                tmpdeviceData[smcpn].devices = {}
              }
              if (tmpdeviceData[smcpn].devices[devicepn] === undefined) {
                tmpdeviceData[smcpn].devices[devicepn] = {}
              }
              tmpdeviceData[smcpn].devices[devicepn].latest_rawdata =
                params.msg[smcpn][devicepn]
            } catch (error) {
              console.log(error)
            }
          })
        })
        vuethis.deviceData = { ...tmpdeviceData }
        vuethis.$forceUpdate()
        vuethis.isloading = false
      } catch (error) {
        console.log(error)
      }
    },
    getRegionDeviceTypeArray_Fan(params) {
      try {
        const sortArray = ['inverterfan', 'inverter', 'fan', 'wetpad']
        let tmpArray = Object.keys(params)
        tmpArray = tmpArray.filter(a => sortArray.indexOf(a) > -1)
        tmpArray.sort((a, b) => {
          try {
            if (sortArray.indexOf(a) > sortArray.indexOf(b)) {
              return 1
            }
            if (sortArray.indexOf(a) < sortArray.indexOf(b)) {
              return -1
            }
            return 0
          } catch (error) {
            console.log(error)
            return 0
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return []
      }
    },
    regiondevices(tmpdevicetype) {
      try {
        const tmpArray = []
        if (Object.keys(this.tmpregiondeviceslist) === 0) {
          return []
        }
        if (
          this.tmpregiondeviceslist[tmpdevicetype] === undefined ||
          this.tmpregiondeviceslist[tmpdevicetype] === null
        ) {
          return []
        }
        if (Object.keys(this.tmpregiondeviceslist[tmpdevicetype]) === 0) {
          return []
        }
        if (
          Object.keys(this.tmpregiondeviceslist[tmpdevicetype].devices) === 0
        ) {
          return []
        }
        Object.keys(this.tmpregiondeviceslist[tmpdevicetype].devices).forEach(
          item => {
            const obj = this.tmpregiondeviceslist[tmpdevicetype].devices[item]
            obj.regionitemkey = item
            tmpArray.push(obj)
          }
        )
        tmpArray.sort((a, b) => {
          try {
            if (a.index !== undefined) {
              if (a.index > b.index) {
                return 1
              }
            }
            const a_deviceindex = parseInt(a.name.replace(/[^0-9]/gi, ''), 10)
            const b_deviceindex = parseInt(b.name.replace(/[^0-9]/gi, ''), 10)
            if (a_deviceindex < b_deviceindex) {
              return -1
            }
            if (a_deviceindex > b_deviceindex) {
              return 1
            }
            return 0
          } catch (error) {
            console.log(error)
            return 0
          }
        })
        return tmpArray
      } catch (error) {
        console.log(error)
        return []
      }
    },
    async getData() {
      try {
        const devicetypeArray = this.getRegionDeviceTypeArray_Fan(
          this.tmpregiondeviceslist
        )
        const nowDate = new Date().getTime()
        if (
          nowDate - this.residenceTime.lastdatatime >
          this.getdatafrequencyTime.lastdatatime
        ) {
          const tmpdevicesArray = []
          devicetypeArray.forEach(tmpdevicetype => {
            Object.keys(
              this.tmpregiondeviceslist[tmpdevicetype].devices
            ).forEach(key => {
              tmpdevicesArray.push(
                this.tmpregiondeviceslist[tmpdevicetype].devices[key]
              )
            })
          })
          this.getLastdata(tmpdevicesArray)
          this.residenceTime.lastdatatime = nowDate
        }
        await Tientech_delay(200)
        if (
          nowDate - this.residenceTime.historydatatime >
          this.getdatafrequencyTime.historydatatime
        ) {
          devicetypeArray.forEach(tmpdevicetype => {
            if (
              Object.keys(this.tmpregiondeviceslist[tmpdevicetype].devices)
                .length > 0
            ) {
              // this.getHistoryData(this.regiondevices(tmpdevicetype), false)
            }
          })
          this.residenceTime.historydatatime = nowDate
        }
      } catch (error) {
        console.log(error)
      }
    },
    getLastdata(tmpdevicesArray) {
      try {
        if (tmpdevicesArray.length > 0) {
          this.getHistoryData(tmpdevicesArray, true)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getHistoryData(regiondevice, getlatestrawdata) {
      try {
        const vuethis = this
        if (regiondevice === undefined) {
          return
        }
        const obj = {}
        const smcdevicelist = []
        regiondevice.forEach(deviceitem => {
          const smcpn = deviceitem.smcpn
          const devicepn = deviceitem.devicepn
          if (smcdevicelist.indexOf(smcpn) === -1) {
            smcdevicelist.push(smcpn)
          }
          if (obj[smcpn] === undefined) {
            obj[smcpn] = {}
          }
          if (obj[smcpn][devicepn] === undefined) {
            obj[smcpn][devicepn] = { devicepn }
          }
          if (vuethis.deviceData === undefined) {
            vuethis.deviceData = {}
          }
          if (vuethis.deviceData[smcpn] === undefined) {
            vuethis.deviceData[smcpn] = {}
            vuethis.deviceData[smcpn].devices = {}
          }
          if (vuethis.deviceData[smcpn].devices[devicepn] === undefined) {
            vuethis.deviceData[smcpn].devices[devicepn] = {}
          }
          if (vuethis.deviceHistoryData === undefined) {
            vuethis.deviceHistoryData = {}
          }
          if (vuethis.deviceHistoryData[smcpn] === undefined) {
            vuethis.deviceHistoryData[smcpn] = {}
          }
          if (vuethis.autogethistory === undefined) {
            vuethis.autogethistory = {}
          }
          if (vuethis.autogethistory[smcpn] === undefined) {
            vuethis.autogethistory[smcpn] = 0
          }
        })
        const datatime = new Date()
        const data_year = datatime.getFullYear()
        const data_month = `0${datatime.getMonth() + 1}`.substr(-2)
        const data_date = `0${datatime.getDate()}`.substr(-2)
        const data_hour = `0${datatime.getHours()}`.substr(-2)
        const data_min = `0${datatime.getMinutes()}`.substr(-2)
        const date_daily = [data_year, data_month, data_date].join('/')
        const tmpdatetime = new Date(`${date_daily} 00:00:00`).getTime()
        vuethis.isloading = true
        if (getlatestrawdata === false) {
          Object.keys(obj).forEach(smcpn => {
            if (
              vuethis.autogethistory[smcpn] !== undefined &&
              vuethis.autogethistory[smcpn] !== 0
            ) {
              const nowDate = new Date().getTime()
              if (nowDate - vuethis.autogethistory[smcpn] >= 10 * 60 * 1000) {
                vuethis.$socket.emit('devicecloud_getdevicehistorydata', {
                  datetime: tmpdatetime,
                  data: obj,
                  devicelist: smcdevicelist,
                  callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
                })
              }
            } else {
              // console.log(smcdevicelist)
              vuethis.$socket.emit('devicecloud_getdevicehistorydata', {
                data: obj,
                devicelist: smcdevicelist,
                callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
              })
            }
          })
        } else {
          vuethis.$socket.emit('devicecloud_getdevicelatestrawdata', {
            data: obj,
            devicelist: smcdevicelist,
            callback_params: `${vuethis.pagename}_${vuethis.random_number}`,
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    getIcon(type) {
      try {
        let icon = ''
        switch (type) {
          case 'inverter':
          case 'inverterfan':
            icon = 'fa-solid fa-fan fa-lg'
            break
          case 'fan':
            icon = 'fa-solid fa-fan fa-lg'
            break
          case 'wetpad':
            icon = 'fa-solid fa-water fa-lg'
            break
          default:
            icon = 'fa-solid fa-question fa-lg'
            break
        }
        return icon
      } catch (error) {
        console.log(error)
        return 'fa-solid fa-question fa-lg'
      }
    },
    getVariant(type, val) {
      try {
        let variant = ''
        if (
          val.datetime === undefined ||
          val.datetime === null ||
          val.datetime === 0
        ) {
          return 'light-secondary'
        }
        switch (type) {
          case 'inverter':
          case 'inverterfan':
          case 'fan':
            if (val.isoffline === false) {
              if (val.operationalstate === 1) {
                variant = 'light-success'
              } else {
                variant = 'light-secondary'
              }
            } else {
              variant = 'light-danger'
            }
            break
          case 'wetpad':
            if (val.isoffline === false) {
              if (val.operationalstate === 1) {
                variant = 'light-info'
              } else {
                variant = 'light-secondary'
              }
            } else {
              variant = 'light-danger'
            }
            break
          default:
            variant = 'light-secondary'
            break
        }
        return variant
      } catch (error) {
        console.log(error)
        return 'light-secondary'
      }
    },
    getSpin(type, val) {
      let spin = ''
      try {
        switch (type) {
          case 'inverter':
          case 'inverterfan':
            if (val.value !== null) {
              if (val.isoffline === false) {
                if (val.value >= 50) {
                  spin = 'fast-spin'
                } else if (val.value >= 40 && val.value < 50) {
                  spin = 'middle-spin'
                } else if (val.value >= 30 && val.value < 40) {
                  spin = 'fa-spin'
                } else if (val.value >= 20 && val.value < 30) {
                  spin = 'middle-slow-spin'
                } else if (val.value < 20) {
                  spin = 'slow-spin'
                } else {
                  spin = ''
                }
              } else {
                spin = ''
              }
            }
            break
          case 'fan':
            if (val.isoffline === false) {
              if (val.operationalstate === 1) {
                spin = 'fast-spin'
              } else {
                spin = ''
              }
            } else {
              spin = ''
            }
            break
          case 'wetpad':
            if (val.isoffline === false) {
              if (val.operationalstate === 1) {
                spin = ''
              } else {
                spin = ''
              }
            } else {
              spin = ''
            }
            break
          default:
            spin = ''
            break
        }
        return spin
      } catch (error) {
        console.log(error)
        return spin
      }
    },
    getTimeFormat(datatime) {
      try {
        if (datatime === undefined || datatime === null || datatime === 0) {
          return 'loading..'
        }
        const nowDatetime = new Date().getTime()
        if (nowDatetime - datatime > 60 * 1000 * 60) {
          return this.$moment(datatime).format('MMMM Do YYYY, h:mm:ss a')
        }
        return this.$moment(datatime).fromNow()
      } catch (error) {
        console.log(error)
        return ''
      }
    },
    getDatetimeisOffline(datatime) {
      try {
        if (datatime === undefined || datatime === null || datatime === 0) {
          return false
        }
        if (new Date().getTime() - datatime < this.overtime) {
          return false
        }
        return true
      } catch (error) {
        console.log(error)
        return true
      }
    },
  },
}
</script>

<style lang="scss">
// .echarts {
//   width: 100%;
//   height: 180px;
// }

// .slow-spin {
//   -webkit-animation: fa-spin 5s infinite linear;
//   animation: fa-spin 5s infinite linear;
// }

// .middle-slow-spin {
//   -webkit-animation: fa-spin 3s infinite linear;
//   animation: fa-spin 3s infinite linear;
// }

// .middle-spin {
//   -webkit-animation: fa-spin 1.5s infinite linear;
//   animation: fa-spin 1.5s infinite linear;
// }

// .fast-spin {
//   -webkit-animation: fa-spin 1s infinite linear;
//   animation: fa-spin 1s infinite linear;
// }
</style>
